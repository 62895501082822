import React, { useState, useEffect } from 'react';
import './App.css'; 

const DynamicFileMappingGUI = () => {
  
  // State variables
  const [sheet1Data, setSheet1Data] = useState([]);
  const [sheet2Data, setSheet2Data] = useState([]);
  const [sheet1Columns, setSheet1Columns] = useState([]);
  const [sheet2Columns, setSheet2Columns] = useState([]);
  const [selectedSheet1Column, setSelectedSheet1Column] = useState('');
  const [selectedSheet2Column, setSelectedSheet2Column] = useState('');
  const [selectedSummaryColumn, setSelectedSummaryColumn] = useState('');
  const [mappedData, setMappedData] = useState({});
  const [summary, setSummary] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [filesUploaded, setFilesUploaded] = useState({ sheet1: false, sheet2: false });
  const [error, setError] = useState(null);
  const [uniqueSheet1Values, setUniqueSheet1Values] = useState([]);
  const [uniqueSheet2Values, setUniqueSheet2Values] = useState([]);
  const [columnFilters, setColumnFilters] = useState({});

  const [selectedCategoricalColumns, setSelectedCategoricalColumns] = useState([]);
  const [selectedAmountColumns, setSelectedAmountColumns] = useState([]);
  const [categoricalSummary, setCategoricalSummary] = useState([]);


  // Function to parse CSV data
  const parseCSV = (text) => {
    try {
      const lines = text.split('\n');
      const headers = lines[0].split(',');
      return lines.slice(1).map(line => {
        const values = line.split(',');
        return headers.reduce((obj, header, index) => {
          obj[header.trim()] = values[index] ? values[index].trim() : '';
          return obj;
        }, {});
      });
    } catch (error) {
      setError('Error parsing CSV: ' + error.message);
      return [];
    }
  };


  // Function to handle file upload
  const handleFileUpload = (event, sheetNumber) => {
    const file = event.target.files[0];
    if (!file) {
      setError('No file selected');
      return;
    }


    const reader = new FileReader();


    reader.onload = (e) => {
      try {
        const text = e.target.result;
        const data = parseCSV(text);
        if (data.length === 0) {
          throw new Error('No data found in the file');
        }
        if (sheetNumber === 1) {
          setSheet1Data(data);
          setSheet1Columns(Object.keys(data[0]));
          setFilesUploaded(prev => ({ ...prev, sheet1: true }));
        } else {
          setSheet2Data(data);
          setSheet2Columns(Object.keys(data[0]));
          setFilesUploaded(prev => ({ ...prev, sheet2: true }));
        }
      } catch (error) {
        setError('Error processing file: ' + error.message);
      }
    };


    reader.onerror = (error) => setError('Error reading file: ' + error.message);


    reader.readAsText(file);
  };

  // Function to handle categorical column selection
  const handleCategoricalColumnSelection = (column) => {
    setSelectedCategoricalColumns((prevColumns) => {
      if (prevColumns.includes(column)) {
        return prevColumns.filter((col) => col !== column);
      } else {
        return [...prevColumns, column];
      }
    });
  };

  // Function to handle amount column selection
  const handleAmountColumnSelection = (column) => {
    setSelectedAmountColumns((prevColumns) => {
      if (prevColumns.includes(column)) {
        return prevColumns.filter((col) => col !== column);
      } else {
        return [...prevColumns, column];
      }
    });
  };

  // Function to build categorical summary
  const buildCategoricalSummary = () => {
    const summary = {};

    selectedCategoricalColumns.forEach((categoricalColumn) => {
      summary[categoricalColumn] = {};

      sheet1Data.forEach((row) => {
        const category = row[categoricalColumn];
        if (!summary[categoricalColumn][category]) {
          summary[categoricalColumn][category] = {};
          selectedAmountColumns.forEach((amountColumn) => {
            summary[categoricalColumn][category][amountColumn] = {
              total: 0,
              count: 0,
            };
          });
        }

        selectedAmountColumns.forEach((amountColumn) => {
          const value = parseFloat(row[amountColumn]);
          if (!isNaN(value)) {
            summary[categoricalColumn][category][amountColumn].total += value;
            summary[categoricalColumn][category][amountColumn].count += 1;
          }
        });
      });
    });

    const formattedSummary = Object.entries(summary).map(([categoricalColumn, categories]) => ({
      categoricalColumn,
      categories: Object.entries(categories).map(([category, amounts]) => ({
        category,
        amounts: Object.entries(amounts).map(([amountColumn, { total, count }]) => ({
          amountColumn,
          total: total.toFixed(2),
          average: count > 0 ? (total / count).toFixed(2) : '0.00',
        })),
      })),
    }));

    setCategoricalSummary(formattedSummary);
    setCurrentStep(3);
  };  
  
  // Function to handle column selection
  const handleColumnSelection = () => {
    if (selectedSheet1Column && selectedSheet2Column) {
      const uniqueValues1 = [...new Set(sheet1Data.map(row => row[selectedSheet1Column]))];
      const uniqueValues2 = [...new Set(sheet2Data.map(row => row[selectedSheet2Column]))];
      setUniqueSheet1Values(uniqueValues1);
      setUniqueSheet2Values(uniqueValues2);
      setCurrentStep(3);
    } else {
      setError('Please select columns from both sheets');
    }
  };


  // Function to handle item mapping
  const handleItemMapping = (sheet1Value, sheet2Value) => {
    setMappedData(prev => {
      const updated = { ...prev, [sheet1Value]: sheet2Value };
      updateSheet1Data(updated);
      return updated;
    });
  };


  // Function to update sheet1Data with mapped categories
  const updateSheet1Data = (newMappedData) => {
    const updatedSheet1Data = sheet1Data.map(row => ({
      ...row,
      MAPPED_CATEGORY: newMappedData[row[selectedSheet1Column]] || 'Not mapped'
    }));
    setSheet1Data(updatedSheet1Data);
    updateSummary(updatedSheet1Data);
  };


  // Function to update summary data
  const updateSummary = (data) => {
    if (!selectedSummaryColumn) return;


    const categorySummary = data.reduce((acc, item) => {
      const category = item.MAPPED_CATEGORY;
      if (category !== 'Not mapped') {
        if (!acc[category]) {
          acc[category] = {
            TOTAL: 0,
            COUNT: 0
          };
        }
        const value = parseFloat(item[selectedSummaryColumn].replace(/[^0-9.-]+/g,""));
        if (!isNaN(value)) {
          acc[category].TOTAL += value;
          acc[category].COUNT += 1;
        }
      }
      return acc;
    }, {});


    const summarizedData = Object.entries(categorySummary).map(([category, data]) => ({
      CATEGORY: category,
      TOTAL: data.TOTAL.toFixed(2),
      COUNT: data.COUNT,
      AVERAGE: data.COUNT > 0 ? (data.TOTAL / data.COUNT).toFixed(2) : '0.00'
    }));


    setSummary(summarizedData);
  };


  // Function to handle manual category update
  const handleManualCategoryUpdate = (index, newCategory) => {
    const updatedSheet1Data = [...sheet1Data];
    updatedSheet1Data[index].MAPPED_CATEGORY = newCategory;
    setSheet1Data(updatedSheet1Data);
    updateSummary(updatedSheet1Data);
  };


  // Function to handle filter change
  const handleFilterChange = (column, value) => {
    setColumnFilters(prev => ({
      ...prev,
      [column]: value
    }));
  };


  // Filter sheet1Data based on column filters
  const filteredSheet1Data = sheet1Data.filter(row =>
    Object.entries(columnFilters).every(([column, filterValue]) =>
      row[column].toString().toLowerCase().includes(filterValue.toLowerCase())
    )
  );


  // Update summary when selectedSummaryColumn changes
  useEffect(() => {
    if (selectedSummaryColumn) {
      updateSummary(sheet1Data);
    }
  }, [selectedSummaryColumn]);


  // Function to generate CSV content for download
  const generateCSVContent = () => {
    const headers = [...sheet1Columns, 'MAPPED_CATEGORY'];
    const dataRows = filteredSheet1Data.map(row =>
      headers.map(header => row[header] || '').join(',')
    );


    const summaryHeaders = ['CATEGORY', 'TOTAL', 'COUNT', 'AVERAGE'];
    const summaryRows = summary.map(row =>
      summaryHeaders.map(header => row[header] || '').join(',')
    );


    const csvContent = [
      headers.join(','),
      ...dataRows,
      '\n',
      summaryHeaders.join(','),
      ...summaryRows
    ].join('\n');


    return csvContent;
  };


  // Function to initiate file download
  const handleDownload = () => {
    const csvContent = generateCSVContent();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'output.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  // Render the current step
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        // Render file upload step
        return (
          <div className="step-content">
            <h2>Step 1: Upload Your File</h2>
            <div className="file-upload-container">
              <div className="file-upload">
                <label htmlFor="sheet1">Sheet 1:</label>
                <input type="file" id="sheet1" onChange={(e) => handleFileUpload(e, 1)} accept=".csv" />
              </div>
              <div className="file-upload">
                <label htmlFor="sheet2">Sheet 2:</label>
                <input type="file" id="sheet2" onChange={(e) => handleFileUpload(e, 2)} accept=".csv" />
              </div>
            </div>
            {sheet1Data.length > 0 && (
              <div className="data-preview">
                <h3>Sheet 1 Data Preview</h3>
                <table>
                  <thead>
                    <tr>
                      {sheet1Columns.map((col, idx) => <th key={idx}>{col}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {sheet1Data.slice(0, 5).map((row, rowIdx) => (
                      <tr key={rowIdx}>
                        {sheet1Columns.map((col, colIdx) => (
                          <td key={colIdx}>{row[col]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {sheet2Data.length > 0 && (
              <div className="data-preview">
                <h3>Sheet 2 Data Preview</h3>
                <table>
                  <thead>
                    <tr>
                      {sheet2Columns.map((col, idx) => <th key={idx}>{col}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {sheet2Data.slice(0, 5).map((row, rowIdx) => (
                      <tr key={rowIdx}>
                        {sheet2Columns.map((col, colIdx) => (
                          <td key={colIdx}>{row[col]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {filesUploaded.sheet1 && filesUploaded.sheet2 && (
              <button className="btn btn-primary" onClick={() => setCurrentStep(2)}>Next</button>
            )}
          </div>
        );
      case 2:
        // Render column selection step for categorical and amount columns
        return (
          <div className="step-content">
            <h2>Step 2: Select Columns for Summary</h2>
            <div className="column-selection">
              <div className="select-container">
                <h3>Select Categorical Columns:</h3>
                {sheet1Columns.map((col, idx) => (
                  <div key={idx}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedCategoricalColumns.includes(col)}
                        onChange={() => handleCategoricalColumnSelection(col)}
                      />
                      {col}
                    </label>
                  </div>
                ))}
              </div>
              <div className="select-container">
                <h3>Select Amount Columns:</h3>
                {sheet1Columns.map((col, idx) => (
                  <div key={idx}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedAmountColumns.includes(col)}
                        onChange={() => handleAmountColumnSelection(col)}
                      />
                      {col}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <button className="btn btn-primary" onClick={buildCategoricalSummary}>
              Build Summary
            </button>
          </div>
        );
      case 3:
        // Render categorical summary
        return (
          <div className="step-content">
            <h2>Step 3: Categorical Summary</h2>
            {categoricalSummary.map(({ categoricalColumn, categories }) => (
              <div key={categoricalColumn}>
                <h3>{categoricalColumn}</h3>
                <table className="summary-table">
                  <thead>
                    <tr>
                      <th>Category</th>
                      {selectedAmountColumns.map((col) => (
                        <React.Fragment key={col}>
                          <th>{col} Total</th>
                          <th>{col} Average</th>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map(({ category, amounts }) => (
                      <tr key={category}>
                        <td>{category}</td>
                        {amounts.map(({ amountColumn, total, average }) => (
                          <React.Fragment key={amountColumn}>
                            <td>{total}</td>
                            <td>{average}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            <button className="btn btn-primary" onClick={() => setCurrentStep(4)}>
              Next
            </button>
          </div>
        );
      
        case 4:
        // Render column selection step for mapping
        return (
          <div className="step-content">
            <h2>Step 4: Select Columns for Mapping</h2>
            <div className="column-selection">
              <div className="select-container">
                <label htmlFor="sheet1-column">Sheet 1 Column:</label>
                <select 
                  id="sheet1-column" 
                  value={selectedSheet1Column} 
                  onChange={(e) => setSelectedSheet1Column(e.target.value)}
                >
                  <option value="">Select Sheet 1 Column</option>
                  {sheet1Columns.map((col, idx) => (
                    <option key={idx} value={col}>{col}</option>
                  ))}
                </select>
              </div>
              <div className="select-container">
                <label htmlFor="sheet2-column">Sheet 2 Column:</label>
                <select 
                  id="sheet2-column" 
                  value={selectedSheet2Column} 
                  onChange={(e) => setSelectedSheet2Column(e.target.value)}
                >
                  <option value="">Select Sheet 2 Column</option>
                  {sheet2Columns.map((col, idx) => (
                    <option key={idx} value={col}>{col}</option>
                  ))}
                </select>
              </div>
            </div>
            <button className="btn btn-primary" onClick={handleColumnSelection}>
              Next
            </button>
          </div>
        );
      
        case 5:
        // Render mapping, data, and summary step
        return (
          <div className="step-content">
            <h2>Step 5: Map Items, View Data, and Summary</h2>
            <div className="mapping-container">
              <div className="mapping-section">
                <h3>Map Items</h3>
                <div className="mapping-list">
                  {uniqueSheet1Values.map((value1, index) => (
                    <div key={index} className="mapping-item">
                      <span>{value1}</span>
                      <select 
                        value={mappedData[value1] || ''} 
                        onChange={(e) => handleItemMapping(value1, e.target.value)}
                      >
                        <option value="">Select {selectedSheet2Column}</option>
                        {uniqueSheet2Values.map((value2, idx) => (
                          <option key={idx} value={value2}>{value2}</option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              </div>
              <div className="summary-section">
                <h3>Summary</h3>
                <div className="summary-column-select">
                  <label htmlFor="summary-column">Select Column to Summarize:</label>
                  <select 
                    id="summary-column" 
                    value={selectedSummaryColumn} 
                    onChange={(e) => setSelectedSummaryColumn(e.target.value)}
                  >
                    <option value="">Select Column</option>
                    {sheet1Columns.filter(col => col !== selectedSheet1Column).map((col, idx) => (
                      <option key={idx} value={col}>{col}</option>
                    ))}
                  </select>
                </div>
                {summary.length > 0 && (
                  <table className="summary-table">
                    <thead>
                      <tr>
                        <th>CATEGORY</th>
                        <th>TOTAL</th>
                        <th>COUNT</th>
                        <th>AVERAGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {summary.map((row, index) => (
                        <tr key={index}>
                          <td>{row.CATEGORY}</td>
                          <td>{row.TOTAL}</td>
                          <td>{row.COUNT}</td>
                          <td>{row.AVERAGE}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <h3>Data Sheet with Mapped Categories</h3>
            <div className="data-sheet">
              <table>
                <thead>
                  <tr>
                    {[...sheet1Columns, 'MAPPED_CATEGORY'].map((col, idx) => (
                      <th key={idx}>
                        <input
                          type="text"
                          placeholder={`Filter ${col}...`}
                          value={columnFilters[col] || ''}
                          onChange={(e) => handleFilterChange(col, e.target.value)}
                        />
                        {col}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredSheet1Data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {sheet1Columns.map((col, colIndex) => (
                        <td key={colIndex}>{row[col]}</td>
                      ))}
                      <td>
                        <select 
                          value={row.MAPPED_CATEGORY || ''} 
                          onChange={(e) => handleManualCategoryUpdate(rowIndex, e.target.value)}
                        >
                          <option value="">Select Category</option>
                          {uniqueSheet2Values.map((category, idx) => (
                            <option key={idx} value={category}>{category}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button className="btn btn-primary" onClick={handleDownload}>Download Output</button>
          </div>
        );
      default:
        return null;
    }
  };

  // Render the component
  return (
    <div className="dynamic-file-mapping-gui">
      <header>
        <h1>Dynamic File Mapping GUI</h1>
      </header>
      <nav>
        <ul>
        <li className={currentStep === 1 ? 'active' : ''} onClick={() => setCurrentStep(1)}>Upload Files</li>
          <li className={currentStep === 2 ? 'active' : ''} onClick={() => setCurrentStep(2)}>Select Summary Columns</li>
          <li className={currentStep === 3 ? 'active' : ''} onClick={() => setCurrentStep(3)}>Categorical Summary</li>
          <li className={currentStep === 4 ? 'active' : ''} onClick={() => setCurrentStep(4)}>Select Mapping Columns</li>
          <li className={currentStep === 5 ? 'active' : ''} onClick={() => setCurrentStep(5)}>Map & Summarize</li>
        </ul>
      </nav>
      <main>
        {error && <div className="error-message">{error}</div>}
        {renderStep()}
      </main>
      <footer>
        &copy; 2023 Dynamic File Mapping GUI
      </footer>
    </div>
  );
};


export default DynamicFileMappingGUI;